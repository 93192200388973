html {
  /* scroll-snap-type: mandatory; */
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

body {
  position: relative;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font-family: 'Spartan', Lato, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

@font-face {
  font-family: 'Spartan';
  src: url('../../public/fonts/Spartan-SemiBold.eot');
  src: url('../../public/fonts/Spartan-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/Spartan-SemiBold.woff2') format('woff2'),
    url('../../public/fonts/Spartan-SemiBold.woff') format('woff'),
    url('../../public/fonts/Spartan-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('../../public/fonts/Spartan-Bold.eot');
  src: url('../../public/fonts/Spartan-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/Spartan-Bold.woff2') format('woff2'),
    url('../../public/fonts/Spartan-Bold.woff') format('woff'),
    url('../../public/fonts/Spartan-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('../../public/fonts/Spartan-ExtraBold.eot');
  src: url('../../public/fonts/Spartan-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/Spartan-ExtraBold.woff2') format('woff2'),
    url('../../public/fonts/Spartan-ExtraBold.woff') format('woff'),
    url('../../public/fonts/Spartan-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('../../public/fonts/Spartan-Black.eot');
  src: url('../../public/fonts/Spartan-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/Spartan-Black.woff2') format('woff2'),
    url('../../public/fonts/Spartan-Black.woff') format('woff'),
    url('../../public/fonts/Spartan-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('../../public/fonts/Spartan-Medium.eot');
  src: url('../../public/fonts/Spartan-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/Spartan-Medium.woff2') format('woff2'),
    url('../../public/fonts/Spartan-Medium.woff') format('woff'),
    url('../../public/fonts/Spartan-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('../../public/fonts/Spartan-Light.eot');
  src: url('../../public/fonts/Spartan-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/Spartan-Light.woff2') format('woff2'),
    url('../../public/fonts/Spartan-Light.woff') format('woff'),
    url('../../public/fonts/Spartan-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('../../public/fonts/Spartan-Thin.eot');
  src: url('../../public/fonts/Spartan-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/Spartan-Thin.woff2') format('woff2'),
    url('../../public/fonts/Spartan-Thin.woff') format('woff'),
    url('../../public/fonts/Spartan-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('../../public/fonts/Spartan-ExtraLight.eot');
  src: url('../../public/fonts/Spartan-ExtraLight.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/Spartan-ExtraLight.woff2') format('woff2'),
    url('../../public/fonts/Spartan-ExtraLight.woff') format('woff'),
    url('../../public/fonts/Spartan-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('../../public/fonts/Spartan-Regular.eot');
  src: url('../../public/fonts/Spartan-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/Spartan-Regular.woff2') format('woff2'),
    url('../../public/fonts/Spartan-Regular.woff') format('woff'),
    url('../../public/fonts/Spartan-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
